import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { productsArrayPropTypes } from 'helpers/propTypes';
import Loading from 'components/commons/Loading';
import Input from 'components/commons/Input';
import Button from 'components/commons/Button';

import './SearchForm.scss';

const validate = (values) => {
  const errors = {};
  if (!values.search) {
    errors.search = 'Debes completar este campo';
  } else if (values.search.length < 3) {
    errors.search = 'La cantidad mínima de letras es 3';
  }
  return errors;
};

const SearchForm = (props) => {
  const {
    handleSubmit, loading, items, onChange, onSubmit, openProductModal, submitting,
  } = props;

  const renderItemsSearch = () => (
    <>
      {items.map((item) => (
        <div
          className="search-item"
          data-testid={`search-item-${item.id}`}
          key={item.id}
          onClick={() => openProductModal(item)}
          role="presentation"
        >
          <div className="row">
            <div className="col-md-4 search-item-image">
              <img src={item.image1} alt={item.name} />
            </div>
            <div className="col-md-8 search-item-info">
              <ul>
                <li className="d-flex flex-row">
                  <span className="search-item-title">
                    {item.name}
                  </span>
                </li>
                <li>
                  <span className="search-item-description">
                    {item.description}
                  </span>
                </li>
                <li>
                  {item.wholesale_price > 0
                    ? (
                      <div className="price-search">
                        <div>
                          <span className="search-item-price">
                            Mayor
                          </span>
                          <span className="search-item-price">
                            {`$${item.price.toLocaleString('de-DE')}`}
                          </span>
                        </div>
                        <div>
                          <span className="search-item-price-may">
                            Por
                            {' '}
                            {item.wholesale_quantity}
                          </span>
                          <span className="search-item-price-may">
                            {`$${(item.wholesale_price * item.wholesale_quantity).toLocaleString('de-DE')}`}
                          </span>
                        </div>
                      </div>
                    )
                    : (
                      <span className="search-item-price">
                        {`$${item.sale ? item.sale.toLocaleString('de-DE') : item.price.toLocaleString('de-DE')}`}
                        {!!item.sale && <span>{`$${item.price.toLocaleString('de-DE')}`}</span>}
                      </span>
                    )}
                </li>
              </ul>
            </div>
          </div>
          <div className="separator" />
        </div>
      ))}
    </>
  );

  return (
    <div className="container" data-testid="search-form">
      <div className="row">
        {loading && <Loading message="Cargando..." size={80} />}
        <div className={`form-container ${loading ? 'd-none' : ''}`}>
          <h4>Buscar productos</h4>
          <form className="redux-form" onChange={onChange} onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Field
                component={Input}
                inputClassName="input-search"
                label="Buscar..."
                name="search"
              />
              <Button
                buttonClass="btn-search"
                disabled={submitting}
                onClick={handleSubmit(onSubmit)}
                text={<i className="fa fa-search" aria-hidden="true" />}
                type="button"
              />
            </div>
          </form>
          <div className="search-items">
            {!!items.length && renderItemsSearch()}
            {!items.length && <span className="search-empty">No se encontraron productos</span>}
          </div>
        </div>
      </div>
    </div>
  );
};

SearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  items: productsArrayPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  openProductModal: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'search',
  validate,
})(SearchForm);
