/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { categoriesArrayPropTypes } from 'helpers/propTypes';

import './Menu.scss';

const Menu = (props) => {
  const {
    categories, closeMenu, isMobile, ulClassName,
  } = props;

  const renderProductsMobile = () => (
    <div className="products-menu-mobile" data-testid="nav-products-mobile">
      <div>
        <NavLink to="/productos" activeClassName="active" onClick={closeMenu} data-testid="nav-products">
          Productos
        </NavLink>
        <label htmlFor="products-menu-mobile-checkbox-0" className="products-menu-mobile-toggle">
          <i className="fa fa-angle-down" />
        </label>
      </div>
      <input type="checkbox" id="products-menu-mobile-checkbox-0" />
      <ul className="products-sub-menu-mobile">
        {categories.map((category) => (
          <li key={`category-${category.id}-mobile`}>
            <NavLink activeClassName="active" onClick={closeMenu} to={`/productos/${category.code}`}>
              {category.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <ul className={ulClassName} data-testid="menu">
      <li role="presentation" onClick={closeMenu}>
        <NavLink to="/home" activeClassName="active" data-testid="nav-home">
          HOME
        </NavLink>
      </li>
      <li className="products-menu-link">
        {!isMobile && (
          <div className="dropdown">
            <NavLink to="/productos" activeClassName="active" onClick={closeMenu} data-testid="nav-products">
              PRODUCTOS
            </NavLink>
            <div className="dropdown-content-productos dropdown-content">
              <ul
                style={{
                  listStyle: 'none', padding: 0, display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)',
                }}
              >
                {categories.map((category) => (
                  <li key={`category-${category.id}`}>
                    <NavLink activeClassName="active" className="top-nav-item top-nav-border-right" onClick={closeMenu} to={`/productos/${category.code}`}>
                      {category.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {isMobile && renderProductsMobile()}
      </li>
      <li role="presentation" onClick={closeMenu}>
        <NavLink to="/ofertas" activeClassName="active" data-testid="nav-faq">
          OFERTAS
        </NavLink>
      </li>
      <li role="presentation" onClick={closeMenu}>
        <NavLink to="/preguntas-frecuentes" activeClassName="active" data-testid="nav-faq">
          PREGUNTAS FRECUENTES
        </NavLink>
      </li>
      <li role="presentation" onClick={closeMenu}>
        <NavLink to="/contacto" activeClassName="active" data-testid="nav-contact">
          CONTACTO
        </NavLink>
      </li>
    </ul>
  );
};

Menu.defaultProps = {
  isMobile: false,
};

Menu.propTypes = {
  categories: categoriesArrayPropTypes.isRequired,
  closeMenu: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  ulClassName: PropTypes.string.isRequired,
};

export default Menu;
