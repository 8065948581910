import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import * as constants from 'redux/constants/app.constants';
import {
  getMinimumPurchase, getAllSliders, getWhatsapp, getDiscount, getCatalogs, getPrices, getInstagram,
  getDataAnalytics, getshowMPButton, getTransferInfo, getClient, getEnvios,
} from 'services/app.services';

export function* minimumPurchaseGet() {
  try {
    const data = yield call(getMinimumPurchase);
    yield put({ type: constants.APP_MINIMUM_PURCHASE_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.APP_MINIMUM_PURCHASE_FAILED, error });
  }
}
export function* discountGet() {
  try {
    const data = yield call(getDiscount);
    yield put({ type: constants.APP_DISCOUNT_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.APP_DISCOUNT_FAILED, error });
  }
}

export function* slidersGetAll() {
  try {
    const data = yield call(getAllSliders);
    yield put({ type: constants.APP_SLIDERS_GET_ALL_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.APP_SLIDERS_GET_ALL_FAILED, error });
  }
}

export function* whatsappGet() {
  try {
    const data = yield call(getWhatsapp);
    yield put({ type: constants.APP_WHATSAPP_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.APP_WHATSAPP_FAILED, error });
  }
}

export function* instagramGet() {
  try {
    const data = yield call(getInstagram);
    yield put({ type: constants.APP_INSTAGRAM_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.APP_INSTAGRAM_FAILED, error });
  }
}

export function* catalogsGet() {
  try {
    const data = yield call(getCatalogs);
    yield put({ type: constants.APP_CATALOGS_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.APP_CATALOGS_FAILED, error });
  }
}

export function* pricesGet() {
  try {
    const data = yield call(getPrices);
    yield put({ type: constants.APP_PRICES_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.APP_PRICES_FAILED, error });
  }
}

export function* dataAnalyticsGet() {
  try {
    const data = yield call(getDataAnalytics);
    yield put({ type: constants.APP_DATA_ANALYTICS_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.APP_DATA_ANALYTICS_FAILED, error });
  }
}

export function* showMPButtonGet() {
  try {
    const data = yield call(getshowMPButton);
    yield put({ type: constants.APP_MP_BUTTON_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.APP_MP_BUTTON_FAILED, error });
  }
}

export function* transferInfoGet() {
  try {
    const data = yield call(getTransferInfo);
    yield put({ type: constants.APP_TRANSFER_INFO_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.APP_TRANSFER_INFO_FAILED, error });
  }
}

export function* clientGet() {
  try {
    const data = yield call(getClient);
    yield put({ type: constants.APP_CLIENT_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.APP_CLIENT_FAILED, error });
  }
}

export function* enviosGet() {
  try {
    const data = yield call(getEnvios);
    yield put({ type: constants.APP_ENVIOS_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.APP_ENVIOS_FAILED, error });
  }
}

export function* watchApp() {
  yield all([
    takeLatest(constants.APP_INITIALIZE_REQUESTED, clientGet),
    takeLatest(constants.APP_INITIALIZE_REQUESTED, minimumPurchaseGet),
    takeLatest(constants.APP_INITIALIZE_REQUESTED, enviosGet),
    takeLatest(constants.APP_INITIALIZE_REQUESTED, slidersGetAll),
    takeLatest(constants.APP_INITIALIZE_REQUESTED, whatsappGet),
    takeLatest(constants.APP_INITIALIZE_REQUESTED, instagramGet),
    takeLatest(constants.APP_INITIALIZE_REQUESTED, catalogsGet),
    takeLatest(constants.APP_INITIALIZE_REQUESTED, pricesGet),
    takeLatest(constants.APP_INITIALIZE_REQUESTED, dataAnalyticsGet),
    takeLatest(constants.APP_INITIALIZE_REQUESTED, showMPButtonGet),
    takeLatest(constants.APP_INITIALIZE_REQUESTED, transferInfoGet),
  ]);
}
