import React, { useEffect,useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, change } from 'redux-form';
import { useSelector } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import ShoppingCart from '@material-ui/icons/ShoppingCart';

import Input from 'components/commons/Input';
import FileInput from 'components/commons/FileInput';
import Button from 'components/commons/Button';

import mercadoPagoImage from 'views/Checkout/assets/mercado-pago.png';

import truckImage from './assets/truck.png';

import './PaymentForm.scss';

const validate = (values) => {
  const errors = {};
  if (!values.delivery_method) {
    errors.delivery_method = 'Debe elegir un método de envío';
  }
  if (!values.comments) {
    errors.comments = 'Comentario inválido';
  }
  return errors;
};

const PaymentForm = (props) => {
  const {
    deliveryMethod, handleSubmit, onSubmit, submitting, onSecureCheckoutClick, showMPButton,
    transferInfo, dispatch, envios,
  } = props;

  const [transfer, setTransfer] = useState(false);
  const formValues = useSelector((state) => state.form.payment);
  const comprobante = !!formValues.values ? formValues.values.comprobante ? true : false :false;

  const handleBack = () => {
    dispatch(change('payment', 'comprobante', ''));
    setTransfer(false);
  };

  return (
    <div className="container" data-testid="payment-form">
      <div className="row">
        <div className="form-container">
          <Avatar className="avatar">
            <ShoppingCart />
          </Avatar>
          <h4>M&eacute;todo de env&iacute;o</h4>
          <form className="redux-form" encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
          {envios.length > 0 ? envios.map(envio=>(
              <div className="checkout-radio">
                <Field
                  formGroupClassName={parseInt(deliveryMethod, 10) === envio.id ? 'radio-active' : ''}
                  name="delivery_method"
                  label=""
                  component={Input}
                  type="radio"
                  value={envio.id.toString()}
                  renderRadioDiv={() => (
                  <div className="radio-button-info">
                    <div className="radio-button-image">
                      <img src={truckImage} className="truck-image" alt="envio-CABA-lenceria" />
                    </div>
                    <div className="radio-button-text">
                      <span>{envio.name}</span>
                      <span className="radio-span-subtitle">
                          {envio.monto_minimo > 0 ? `¡ENVIO GRATIS SUPERANDO LOS $${envio.monto_minimo}!` : ""}
                        </span>
                    </div>
                  </div>
                  )}
                  />
                </div>))
              :<></>}  
            <Field
              component={Input}
              label="Transporte de preferencia / Comentarios"
              isTextArea
              name="comments"
            />

            <Button text="Arreglar pago con el vendedor" type="submit" buttonClass="btn-block" disabled={submitting} />
            {showMPButton
              && (
              <Button
                text={(
                  <div className="secure-checkout-button-content justify-content-center">
                    <img src={mercadoPagoImage} alt="mercado pago" height="18" />
                    {' '}
                    Pagar con Mercado Pago
                  </div>
                )}
                type="button"
                onClick={handleSubmit(onSecureCheckoutClick)}
                buttonClass="btn-block secure-checkout-button"
                disabled={submitting}
              />
              )}
          </form>
        </div>
      </div>
    </div>
  );
};

PaymentForm.defaultProps = {
  deliveryMethod: null,
};

PaymentForm.propTypes = {
  deliveryMethod: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  onSecureCheckoutClick: PropTypes.func.isRequired,
  showMPButton: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'payment',
  validate,
})(PaymentForm);
