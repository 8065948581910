import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Select from 'react-select';
import {
  Accordion, AccordionDetails, AccordionSummary, Slider,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import PropTypes from 'prop-types';

import {
  categoriesArrayPropTypes, categoryPropTypes, productsArrayPropTypes,
} from 'helpers/propTypes';

import Breadcrumb from 'components/commons/Breadcrumb';
import Button from 'components/commons/Button';
import Loading from 'components/commons/Loading';
import Benefit from 'components/commons/Benefit';

import './Products.scss';

const Products = (props) => {
  const {
    category, categories, checked, filterItems, handleCheckboxChange, handleInputRangeChange,
    handlePaginationChange, handleSubCategory, inputRangeValue, items, loading, maxPrice,
    minimumPurchase, name, orderProducts, page, productsLoading, renderItem, setOrderProducts,
    subCategories, subCategory, total, url,
  } = props;

  const [categoriesAccordion, setCategoriesAccordion] = useState(false);
  const [subCategoriesAccordion, setSubCategoriesAccordion] = useState(false);
  const [priceAccordion, setPriceAccordion] = useState(false);

  const options = [
    { value: 'A-Z', label: 'Predeterminado' },
    { value: 'ascendente', label: 'Precio: mas bajo a mas alto' },
    { value: 'descendente', label: 'Precio: mas alto a mas bajo' },
    { value: 'A-Z', label: 'A-Z' },
    { value: 'Z-A', label: 'Z-A' },
  ];

  const renderCategories = () => (
    <div className="sidebar-section">
      <div className="sidebar-title show-filter-categorys">
        <h5>Categor&iacute;as</h5>
      </div>
      <ul className="sidebar-categories show-filter-categorys" data-testid="sidebar-categories">
        <li key="category-all">
          <NavLink exact to={url}>Todas</NavLink>
        </li>
        {categories.map((cat) => (
          <li key={`category-${cat.id}`}>
            <NavLink
              to={`${url}/${cat.code}`}
              activeClassName="active"
            >
              {cat.name}
            </NavLink>
          </li>
        ))}
      </ul>
      <Accordion className="show-filter-categorys-secondary" expanded={categoriesAccordion}>
        <AccordionSummary
          expandIcon={<i className="fa fa-expand" aria-hidden="true" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => setCategoriesAccordion((prev) => !prev)}
        >
          <h5>Categor&iacute;as</h5>
        </AccordionSummary>
        <AccordionDetails>
          <ul className="sidebar-categories " data-testid="sidebar-categories">
            <li key="category-all">
              <NavLink exact to={url}>Todas</NavLink>
            </li>
            {categories.map((cat) => (
              <li key={`category-${cat.id}`}>
                <NavLink
                  to={`${url}/${cat.code}`}
                  activeClassName="active"
                  onClick={() => setCategoriesAccordion(false)}
                >
                  {cat.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </AccordionDetails>
      </Accordion>
    </div>
  );

  const renderSubCategories = () => (
    <div className="sidebar-section">
      <div className="sidebar-title show-filter-categorys">
        <h5>Sub Categor&iacute;as</h5>
      </div>
      <ul className="sidebar-categories show-filter-categorys" data-testid="sidebar-categories">
        <li key="subcategory-all">
          <a className={`subcategory ${!subCategory ? 'subCategory-active' : ''}`} href={() => false} onClick={() => handleSubCategory()}>Todas</a>
        </li>
        {subCategories.map((cat) => (
          <li key={`subcategory-${cat.id}`}>
            <a
              className={`subcategory ${subCategory === cat ? 'subCategory-active' : ''}`}
              href={() => false}
              onClick={() => handleSubCategory(cat)}
            >
              {cat.name}
            </a>
          </li>
        ))}
      </ul>
      <Accordion className="show-filter-categorys-secondary" expanded={subCategoriesAccordion}>
        <AccordionSummary
          expandIcon={<i className="fa fa-expand" aria-hidden="true" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => setSubCategoriesAccordion((prev) => !prev)}
        >
          <h5>Sub Categor&iacute;as</h5>
        </AccordionSummary>
        <AccordionDetails>
          <ul className="sidebar-categories " data-testid="sidebar-categories">
            <li key="subcategory-all">
              <a
                className={`subcategory ${!subCategory ? 'subCategory-active' : ''}`}
                href={() => false}
                onClick={() => handleSubCategory()}
              >
                Todas
              </a>
            </li>
            {subCategories.map((cat) => (
              <li key={`subcategory-${cat.id}`}>
                <a
                  className={`subcategory ${subCategory === cat ? 'subCategory-active' : ''}`}
                  href={() => false}
                  onClick={() => handleSubCategory(cat)}
                >
                  {cat.name}
                </a>
              </li>
            ))}
          </ul>
        </AccordionDetails>
      </Accordion>
    </div>
  );

  const renderPriceFilter = () => (
    <div>
      <div className="sidebar-section show-filter-categorys" data-testid="sidebar-price-filter">
        <div className="sidebar-title">
          <h5>Filtrar por precio</h5>
          <h5>
            $
            {inputRangeValue[0].toLocaleString('de-DE')}
            {' - $'}
            {inputRangeValue[1].toLocaleString('de-DE')}
          </h5>
        </div>
        <Slider
          value={inputRangeValue}
          onChange={handleInputRangeChange}
          aria-labelledby="range-slider"
          min={0}
          max={maxPrice}
          data-testid="input-range"
        />
        <br />
        <br />
        <div>
          <input type="checkbox" checked={checked} onChange={handleCheckboxChange} />
          <h8 className="offers">Solo ofertas</h8>
        </div>
        <br />
        <div className="filterOrdenar">
          <h8 className="ordenar">Ordenar por:</h8>
          <Select defaultValue={orderProducts} options={options} onChange={setOrderProducts} placeholder="Precio o Letra" />
        </div>
        <Button
          text="Filtrar"
          buttonClass="filter-price-button"
          onClick={filterItems}
        />
      </div>
      <div className="sidebar-section show-filter-categorys-secondary" data-testid="sidebar-price-filter">
        <Accordion className="show-filter-categorys-secondary" expanded={priceAccordion}>
          <AccordionSummary
            expandIcon={<i className="fa fa-expand" aria-hidden="true" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setPriceAccordion((prev) => !prev)}
          >
            <h5>Filtros</h5>
          </AccordionSummary>
          <AccordionDetails>
            <ul className="sidebar-categories">
              <li key="category-all">
                <div className="sidebar-title">
                  <h5>Filtrar por precio</h5>
                  <h5>
                    $
                    {inputRangeValue[0].toLocaleString('de-DE')}
                    {' - $'}
                    {inputRangeValue[1].toLocaleString('de-DE')}
                  </h5>
                </div>
                <Slider
                  value={inputRangeValue}
                  onChange={handleInputRangeChange}
                  aria-labelledby="range-slider"
                  min={0}
                  max={maxPrice}
                  data-testid="input-range"
                />
              </li>
              <li>
                <input type="checkbox" checked={checked} onChange={handleCheckboxChange} />
                <h8 className="offers">Solo ofertas</h8>
              </li>
              <br />
              <li>
                <h8 className="ordenar">Ordenar por:</h8>
                <Select defaultValue="A-Z" options={options} onChange={setOrderProducts} placeholder="Precio o Letra" />
              </li>
              <li>
                <Button
                  text="Flitrar"
                  buttonClass="filter-price-button"
                  onClick={() => {
                    filterItems();
                    setPriceAccordion(false);
                  }}
                />
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>

  );

  const renderPagination = () => (
    <div className="row text-center" data-testid="pagination">
      <div className="col">
        <Pagination
          count={total}
          page={page}
          onChange={handlePaginationChange}
          variant="outlined"
          shape="rounded"
        />
      </div>
    </div>
  );

  const renderItemsWithPagination = () => (
    <>
      {renderPagination()}
      <br />
      <div
        className="row paginated"
        style={{ margin: '-19px' }}
      >
        {items.map((item) => renderItem(item))}
      </div>
      <br />
      {renderPagination()}
    </>
  );

  const renderNoItems = () => (
    <h2 className="text-center">
      No se encontraron productos
    </h2>
  );

  const getUrls = () => {
    const urls = [url];
    if (category) {
      const categoryUrl = `${url}/${category.code}`;
      urls.push(categoryUrl);
    }
    return urls;
  };

  const getNames = () => {
    const names = [name];
    if (category) {
      names.push(category.name);
    }
    return names;
  };

  const renderLoading = () => (
    <div className="products-loading">
      <Loading />
    </div>
  );

  const renderMainContent = () => (
    <div className="col clearfix">
      <Breadcrumb
        urls={getUrls()}
        names={getNames()}
      />
      <div className="section">
        <div className="sidebar">
          {renderCategories()}
          {renderSubCategories()}
          {renderPriceFilter()}
        </div>
        <div className="main-content">
          <div className="container" data-testid="paginator">
            {items.length > 0 && renderItemsWithPagination()}
            {items.length === 0 && productsLoading && renderNoItems()}
            {items.length === 0 && !productsLoading && renderLoading()}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div data-testid="products-page">
      <div className="container">
        <div className="row">
          {loading && renderLoading()}
          {!loading && renderMainContent()}
        </div>
      </div>
      <div className="section">
        <Benefit minimumPurchase={minimumPurchase} />
      </div>
    </div>
  );
};

Products.defaultProps = {
  category: null,
  orderProducts: null,
  subCategory: null,
};

Products.propTypes = {
  category: categoryPropTypes,
  categories: categoriesArrayPropTypes.isRequired,
  checked: PropTypes.bool.isRequired,
  filterItems: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  handleInputRangeChange: PropTypes.func.isRequired,
  handlePaginationChange: PropTypes.func.isRequired,
  handleSubCategory: PropTypes.func.isRequired,
  inputRangeValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  items: productsArrayPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  maxPrice: PropTypes.number.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  orderProducts: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  page: PropTypes.number.isRequired,
  productsLoading: PropTypes.bool.isRequired,
  renderItem: PropTypes.func.isRequired,
  setOrderProducts: PropTypes.func.isRequired,
  subCategories: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  subCategory: PropTypes.shape(),
  total: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
};

export default Products;
