import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { categoriesArrayPropTypes, userPropTypes } from 'helpers/propTypes';

import Menu from 'components/main/Header/Menu';
import LogoContainer from 'containers/LogoContainer';

import './Navbar.scss';

const Navbar = (props) => {
  const {
    cartSize, categories, closeMenu, isMenuActive, isUserLoggedIn, logout, openLoginModal,
    openMenu, openSearchModal, user,
  } = props;
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => setScrollY(window.scrollY);

  useEffect(() => {
    // Agregar un event listener cuando el componente se monta
    window.addEventListener('scroll', handleScroll);

    // Limpiar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const renderResponsiveMenu = () => (
    <>
      <div
        className={`fs-menu-overlay${isMenuActive ? ' pointer-active' : ''}`}
        role="presentation"
        onClick={closeMenu}
      />
      <div className={`hamburger-menu${isMenuActive ? ' active' : ''}`} style={{ top: 100 - scrollY }}>
        <div className="hamburger-menu-content">
          <Menu
            categories={categories}
            closeMenu={closeMenu}
            isMobile
            isUserLoggedIn={isUserLoggedIn}
            ulClassName="menu-top-nav"
          />
        </div>
      </div>
    </>
  );

  return (
    <div data-testid="navbar">
      <div className="main-nav-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar">
                <div className="navbar-content">
                  {isMenuActive ? (
                    <div
                      className="hamburger-container"
                      role="presentation"
                      onClick={closeMenu}
                    >
                      <i className="fa fa-times" aria-hidden="true" />
                    </div>
                  ) : (
                    <div
                      className="hamburger-container"
                      role="presentation"
                      onClick={openMenu}
                    >
                      <i className="fa fa-bars" aria-hidden="true" />
                    </div>
                  )}
                  <LogoContainer />
                  <Menu
                    categories={categories}
                    closeMenu={closeMenu}
                    isUserLoggedIn={isUserLoggedIn}
                    ulClassName="navbar-menu"
                  />
                  <ul className="navbar-user">
                    <li>
                      <div className="navbar-icon" role="presentation" onClick={openSearchModal}>
                        <i className="fa fa-search" aria-hidden="true" />
                      </div>
                    </li>
                    {!user
                      ? (
                        <li>
                          <div className="navbar-icon" role="presentation" onClick={openLoginModal}>
                            <i className="fa fa-sign-in" aria-hidden="true" />
                          </div>
                        </li>
                      )
                      : (
                        <li>
                          <div className="navbar-icon" role="presentation">
                            <div className="dropdown">
                              <i className="fa fa-user" aria-hidden="true" />
                              <div className="dropdown-content">
                                <NavLink className="top-nav-item top-nav-border-right" to="/perfil">
                                  <i className="fa fa-user" aria-hidden="true" />
                                  {' '}
                                  Mi cuenta
                                </NavLink>
                                <NavLink to="/compras" className="top-nav-item top-nav-border-right" data-testid="nav-purchases">
                                  Mis compras
                                </NavLink>
                                <a href={() => false} onClick={logout}>Cerrar sesi&oacute;n</a>
                              </div>
                            </div>
                          </div>
                        </li>
                      )}

                    <li>
                      <Link to="/carrito">
                        <div className="navbar-icon">
                          <i className="fa fa-shopping-bag" aria-hidden="true" />
                          <span id="checkout-items" className="checkout-items">
                            {cartSize}
                          </span>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {renderResponsiveMenu()}
    </div>
  );
};

Navbar.defaultProps = {
  user: null,
};

Navbar.propTypes = {
  cartSize: PropTypes.number.isRequired,
  categories: categoriesArrayPropTypes.isRequired,
  closeMenu: PropTypes.func.isRequired,
  isMenuActive: PropTypes.bool.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  openLoginModal: PropTypes.func.isRequired,
  openMenu: PropTypes.func.isRequired,
  openSearchModal: PropTypes.func.isRequired,
  user: userPropTypes,
};

export default Navbar;
