import { get } from 'services/api';

export const getMinimumPurchase = async () => {
  const response = await get('minimumAmount');
  return response;
};

export const getDiscount = async () => {
  const response = await get('discount');
  return response;
};

export const getAllSliders = async () => {
  const response = await get('sliders');
  return response;
};

export const getWhatsapp = async () => {
  const response = await get('whatsapp');
  return response;
};

export const getCatalogs = async () => {
  const response = await get('catalogs');
  return response;
};

export const getPrices = async () => {
  const response = await get('prices');
  return response;
};

export const getInstagram = async () => {
  const response = await get('socialMedia');
  return response;
};

export const getDataAnalytics = async () => {
  const response = await get('dataAnalytics');
  return response;
};

export const getshowMPButton = async () => {
  const response = await get('showMPButton');
  return response;
};

export const getTransferInfo = async () => {
  const response = await get('transferInfo');
  return response;
};

export const getClient = async () => {
  const response = await get('design');
  return response;
};

export const getEnvios = async () => {
  const response = await get('envios');
  return response;
};
